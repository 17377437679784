<script>
import {bus} from "../../router/bus";
export default {
  name: "CardRateShort.vue",
  props: {
    rate: {
      type: [Object, Array]
    },
    
    orderButton: {
      type: Boolean,
      default: true,
    }
  },
  
  computed: {
    getClientId() {
      return Number(this.$_getsetting('client_id'))
    }
  },
  
  data: () => ({
  }),
  
  methods: {
    getNumber(value){
      return parseFloat(value).toLocaleString('ru-RU')
    },
  
    buyCard(item){
      bus.$emit("show_send_request", { rate: item});
    },
  
    openrateview(v_key, v_on_date){
      let routeData = this.$router.resolve({ path: "/RatePage", query : { key : v_key, on_date : v_on_date, cid: this.$_getsetting("client_id") } });
      window.open(routeData.href, '_blank');
    },
  }
}
</script>

<template>
  <div
    class="rate-card"
    :id="rate.id"
    v-if="rate.is_visible == 1"
  >
    <div class="rate-card__title" @click="openrateview(rate.key_orig, rate.on_date)">
      <div class="rate-card__title-caption">
        {{ rate.title}}
      </div>
      <div class="rate-card__title-cost">
        {{getNumber(rate.total)}}&nbsp;&#8381;
      </div>
    </div>
    <div class="rate-card__subtitle">
      <div class="rate-card__subtitle-info">
        <div v-if="rate.is_hide_line == 0 || getClientId == -1">
          Перевозчик:&nbsp;{{rate.line_name}}&nbsp;
        </div>
        <div v-else>
          Перевозчик:&nbsp;Vedexx
        </div>
        <div>
          Экспедитор:&nbsp;{{rate.charterer_name}}&nbsp;
        </div>
        <div>
          Терминал:&nbsp;{{(rate.is_direct == 0) ? rate.terminal_name : (rate.is_ete == 1) ? rate.across_place_name_ext : "" }}&nbsp;
        </div>
        <div>
          Порт отправки:&nbsp;{{ (rate.base_place_from_name_ext.length > 0) ? rate.base_place_from_name_ext : rate.base_place_from_name }}&nbsp;
        </div>
        <div>
          &nbsp;{{ (rate.is_ete == 1) ? "Сквозной сервис" : "" }}&nbsp;
        </div>
      </div>
      <div class="rate-card__subtitle-cost">
        {{getNumber(rate.usd_total)}}&nbsp;&#36;
      </div>
    </div>
    <div class="rate-card__actions" v-if="orderButton === true">
      <v-btn
        class="rate-card__actions-btn"
        color="var(--main-orange)"
        dark
        depressed
        rounded
        small
        type="submit"
        @click="buyCard(rate)"
      >
        Оформить
      </v-btn>
    </div>
  </div>
</template>

<style scoped lang="scss">
.rate-card {
  color: #1d1c1a;
  width: 95%;
  padding: 10px 5px;
  border-radius: 10px;
  margin: 1% 2.5%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #d6d9d9;
  
  &:hover {
    box-shadow: 0vw 0.15625vw 0.05208vw -0.1041666vw rgba(96, 97, 99, 0.2),
    0vw 0.1041666vw 0.1041666vw 0vw rgba(96, 97, 99, 0.14),
    0vw 0.05208vw 5px 0vw rgba(96, 97, 99, 0.12);
    border: 1px solid #e9eaea;
  }
  
  &:hover &__title{
    color: var(--main-orange);
  }
  
  &__title {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
    
    &-caption {
      width: 65%;
      margin: auto;
      font-size: 14pt;
      font-weight: bold;
      cursor: pointer;
      border-bottom: solid 1px #a7c2d3;
    }
    
    &-cost {
      width: 15%;
      margin: auto;
      font-size: 14pt;
      font-weight: bold;
      border-bottom: solid 1px #a7c2d3;
      text-align: center;
    }
  }
  
  &__subtitle {
    font-size: 10pt;
    font-style: italic;
    margin-bottom: 0.425%;
    margin-left: 0.85%;
    margin-right: 0.85%;
    width: 98.39%;
    color:#6c8693;
    display: flex;
    flex-direction: row;
    
    &-info {
      width: 80%;
      display: flex;
      flex-direction: row;
      margin: 5px auto 0;
    }
    
    &-cost {
      font-style: normal;
      width: 13%;
    }
  }
  
  &__actions {
    border-top: #e6eae9 2px solid;
    color: #92a1a6;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 92%;
    line-height: 2vw;
    background-color: #fff;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 10px auto 0;
    align-content: flex-end;
    
    &-btn {
      bottom: 15px;
      margin-bottom: -10px;
      text-transform: none;
      font-weight: 600;
    }
  }
}
</style>
